// import { useState, useEffect } from "react";
// import { issueApiRequest } from "../utils";
import { useState } from "react";

// const USE_GCF = false;

export const usePhoto = (photoSources, photoUrl) => {
  const photos = photoSources;
  // const [photos, setPhotos] = useState(photoSources);
  // const url = photoUrl;
  // useEffect(() => {
  //   let didCancel = false;
  //   const fetch = async () => {
  //     const response = await issueApiRequest(url);
  //     if (USE_GCF && !didCancel && response) setPhotos(response);
  //   };
  //   fetch();
  //   return () => {
  //     didCancel = true;
  //   };
  // }, []);

  const [photoAttribute, setPhotoAttribute] = useState({
    index: 0,
    onPrev: false,
    onNext: true,
  });

  const handleScroll = (num) => {
    if (num === -1) {
      const index = photoAttribute.index - 4;
      const onPrev = index > 0;
      const onNext = index + 4 < photos.length;
      setPhotoAttribute({
        index: index,
        onPrev,
        onNext,
      });
    }
    if (num === 1) {
      const index = photoAttribute.index + 4;
      const onPrev = index > 0;
      const onNext = index + 4 < photos.length;
      setPhotoAttribute({
        index: index,
        onPrev,
        onNext,
      });
    }
  };
  const handlePrev = () => handleScroll(-1);
  const handleNext = () => handleScroll(1);

  return {
    photos,
    handlePrev,
    handleNext,
    photoAttribute,
  };
};
