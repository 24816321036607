export const IMAGE_URL =
  "https://storage.googleapis.com/johnnivan-static-assets/";

// GCF: インスタグラムの投稿情報一覧取得エンドポイント
export const INSTAGRAM_ACCESS_TOKEN =
  "EAAN12Cnm6QUBABVKhDZCWZB4MGGQipllDzFYK8ZBrJWtTJEsmwClHQZBjPSZBwDZAWUJNfSAamgj5CabpKZA7rCDXChAG8wQvOG5wnKnG5cMiqEjs2ODtJZCZCa0HE9sW8ZB0j6KSs91FHlLIQyfJZCZAteuZBg2gDqZAJiznAFlERNUOMNoxZAY83SbU4p";
export const GET_INSTAGRAM_URL =
  "https://asia-northeast1-johnnivan-web-project.cloudfunctions.net/getInstagramPost";

export const INSTAGRAM_CLIENT_TOKEN =
  "973996206516485|cbf5617e9f918c1f51ed6c8610fee3d9";

export const PHOTO_DISPLAY_COUNT = 32;
// コンタクト用のメールアドレス
export const MAIL_ADDRESS = "welovejohnnivan@gmail.com";
// emailへのリンク
export const MAIL_TO = `mailto:${MAIL_ADDRESS}`;
// copyright
export const COPYRIGHT = "Copyright © Johnnivan All Rights Reserved.";
// youtube channel page
export const YOUTUBE_PAGE =
  "https://www.youtube.com/channel/UC-e4OyD2-FUuITUeB51v8Ng";
export const INSTAGRAM_LINK =
  "https://www.instagram.com/welovejohnnivan/?hl=ja";
export const TWITTER_LINK = "https://twitter.com/welovejohnnivan";
export const BANDCAMP_LINK = "https://johnnivan.bandcamp.com/";
export const SPOTIFY_LINK =
  "https://open.spotify.com/artist/27JM9HvAAcjGJPZCoZLgWe";
export const APPLE_MUSIC_LINK =
  "https://music.apple.com/jp/artist/johnnivan/1387469938";
