const contentful = require("contentful");

const client = contentful.createClient({
  space: "rgu433c46pdb",
  accessToken: "7_IUEm2ozCg4NlVnRaqyxd81ENu8wham30Q9TE_yEZY",
});

export const fetchLives = async () => {
  // コンテンツを取得
  const entries = await client.getEntries({
    content_type: "liveInformation",
  });
  console.log(entries.items);
  // コンテンツがない時空の配列を返す
  if (!entries || !entries.items || !entries.items.length) {
    return [];
  }

  const liveInfo = entries.items.map((item) => {
    const { title, date, place, content: contentSource, cover } = item.fields;
    const content = contentSource.split("\n"); // contentを表示用に整形

    return {
      title,
      date,
      place,
      content,
      cover,
    };
  });
  return liveInfo;
};
fetchLives();
