// react
import React from 'react';
// mui
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		backgroundImage: (props) => `url(${props.imageURL})`,
		backgroundAttachment: 'fixed',
		backgroundPosition: '50% 40%',
		backgroundSize: 'cover',
		height: '100vh',
		width: '100%'
	}
}));

export const HeaderImageD = ({ imageURL }) => {
	const c = useStyles({ imageURL });
	return <div className={c.root} />;
};
