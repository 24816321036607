// react
import React from "react";
import cx from "classnames";
// mui
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import LiveItem from "./LiveItem";
import { useLive } from "../hooks";

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonWrapper: {
    textAlign: "center",
  },
  button: {
    width: "60%",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: 1,
    [theme.breakpoints.up("md")]: {
      width: "100%",
      fontSize: "1.4rem",
    },
  },
  viewMoreButton: {
    border: "solid 1px #111",
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[1],
  },
  ticketButton: {
    background: "#111",
    color: "#fff",
    boxShadow: theme.shadows[1],
  },
  contentTitleOnNoData: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    fontSize: "1rem",
    fontWeight: 900,
    letterSpacing: "20",
  },
}));

// Booking機能追加時にtrueにする
const isTicketAvailable = false;

const Lives = ({ available }) => {
  const c = useStyles();
  const { lives } = useLive();
  console.log("lives: ");
  console.log(lives);
  const liveCount = lives.length;
  return available ? (
    <div className={c.root}>
      {lives &&
        lives.length &&
        lives.map((live, index) => (
          <LiveItem
            key={live.title}
            live={live}
            lastItem={index === liveCount - 1}
          />
        ))}
      {/* LIVEが3件以上ある時VIEW_MOREボタンを表示する */}
      {liveCount >= 3 && (
        <div className={c.buttonWrapper}>
          <Button variant="outlined" className={cx(c.button, c.viewMoreButton)}>
            VIEW MORE
          </Button>
        </div>
      )}
      {/* Booking機能 */}
      {isTicketAvailable && (
        <div className={c.buttonWrapper}>
          <Button className={cx(c.button, c.ticketButton)} variant="contained">
            TICKETS
          </Button>
        </div>
      )}
    </div>
  ) : (
    <div className={c.root}>
      <Typography
        variant="h4"
        component="h3"
        align="center"
        className={c.contentTitleOnNoData}
      >
        NO UPCOMING SHOW DATES DUE TO COVID-19
      </Typography>
    </div>
  );
};

export default Lives;
