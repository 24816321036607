import { IMAGE_URL } from '../constants';

const getPrefix = (isDesktop) => (isDesktop ? '' : '-xs');

// カバー画像
export const resolveCoverURL = (desktop = false) => `${IMAGE_URL}global/cover${getPrefix(desktop)}.jpeg?hl=ja`;

// 背景画像
export const resolveNamedCoverURL = (name) => `${IMAGE_URL}global/${name}.jpeg?hl=ja`;

// ロゴURL
export const resolveLogoURL = () => `${IMAGE_URL}global/logowhite.svg`;

export const resolveImageStyle = ({ imageURL, desktop = false, backgroundPosition = null, height = null }) =>
  desktop
    ? {
        backgroundImage: `url(${imageURL})`,
        width: '100%',
        height: height || 600,
        backgroundPosition: backgroundPosition,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
      }
    : {
        backgroundImage: `url(${imageURL})`,
        width: '100%',
        height: height || 200,
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        backgroundSize: 'contain',
      };
