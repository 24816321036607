import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'block',
		position: 'fixed',
		top: 0,
		left: 0,
		zIndex: -1,
		width: '100%',
		height: '110vh'
		// backgroundImage: 'url(https://storage.googleapis.com/johnnivan-static-assets/global/cover-xs.jpeg?hl=ja)',
		// backgroundSize: 'cover',
		// backgroundPosition: 'center',
		// backgroundRepeat: 'no-repeat',
	}
}));

export const HeaderImageM = ({ imageURL }) => {
	const c = useStyles();
	// const prefix = dtype === 'mobile-landscape' ? '-xsl' : '-xs';
	// const imageURL = `https://storage.googleapis.com/johnnivan-static-assets/global/cover${prefix}.jpeg?hl=ja`;
	const style = {
		backgroundImage: `url(${imageURL})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat'
	};
	return <div className={c.root} style={style} />;
};
