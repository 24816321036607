// react
import React, { useEffect } from 'react';
import cx from 'classnames';
import { loadCSS } from 'fg-loadcss';
// mui
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import AppleIcon from '@material-ui/icons/Apple';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import { renderSongName } from '../utils';

const useStyles = makeStyles((theme) => ({
  rootOnDrawer: {
    backgroundColor: '#fefefe',
    [theme.breakpoints.up('sm')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      marginBottom: theme.spacing(40),
    },
    maxWidth: 960,
  },
  cover: {
    marginBottom: theme.spacing(1),
  },
  contentTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 600,
  },
  albumSection: {
    maxWidth: '200px',
    margin: 'auto',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  button: {
    height: '40px',
    width: '200px',
    marginTop: theme.spacing(1),
    borderRadius: '1px',
    border: 'solid 1px #000',
    boxShadow: theme.shadows[0.1],
    fontWeight: 600,
  },
  songHeadline: {
    fontSize: '1.1rem',
    fontWeight: 600,
    borderBottom: 'solid 1px #999',
  },
  songTitle: {
    fontFamily: 'Helvetica',
    letterSpacing: -0.2,
    padding: 0,
    margin: 0,
    marginTop: theme.spacing(0.5),
  },
  attributes: {
    marginTop: theme.spacing(2),
  },
  attribute: {
    fontSize: '0.85rem',
    fontFamily: 'Helvetica Neue',
    color: '#707070',
  },
  iconButton: {
    width: '56px',
    height: '56px',
    color: '#111',
  },
  iconButtonAppleMusic: {
    // 例外、appleMusicのアイコンの位置を調整
    // 固定で18px
    paddingBottom: theme.spacing(2.25),
  },
  icon: {
    width: '30px',
    height: '30px',
    opacity: 1,
  },
  closeIconButton: {
    display: 'inline-block',
    width: '56px',
    height: '56px',
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  adjustment: {
    width: '100%',
    height: '28px',
  },
}));

export const Album = ({ kind = 'dialog', discography }) => {
  const c = useStyles();
  useEffect(() => {
    loadCSS('https://use.fontawesome.com/releases/v5.12.0/css/all.css', document.querySelector('#font-awesome-css'));
  }, []);
  const { title, cover, songs, attributes, isTowerRecordEnable = false } = discography;
  const coverStyle = { backgroundImage: `url(${cover})`, height: 200, width: 200, backgroundSize: 'cover' };
  const FAIconStyle = {
    marginTop: '6px',
    width: 36,
    height: 36,
    '&:hover, &:focus': {
      textDecoration: 'inherit',
    },
  };
  return (
    <div className={kind === 'drawer' && c.rootOnDrawer}>
      {/* 閉じるボタン */}
      <IconButton className={c.closeIconButton} aria-label="HeaderMenu">
        <CloseIcon className={c.icon} />
      </IconButton>
      <div className={c.adjustment} />
      {/* タイトル */}
      <Typography variant="h5" component="h4" className={c.contentTitle}>
        {title}
      </Typography>
      {/* アルバム詳細 */}
      <div className={c.albumSection}>
        <div className={c.cover} style={coverStyle} />
        <Link target="_blank" href={discography.links.appleMusic} style={{ textDecoration: 'none' }}>
          <IconButton className={cx(c.iconButton, c.iconButtonAppleMusic)} aria-label="HeaderMenu">
            <AppleIcon className={c.icon} />
          </IconButton>
        </Link>
        <Link target="_blank" href={discography.links.spotify} style={{ textDecoration: 'none' }}>
          <IconButton className={c.iconButton} aria-label="HeaderMenu">
            <Icon className="fab fa-spotify" style={FAIconStyle} />
          </IconButton>
        </Link>
        <Link target="_blank" href={discography.links.bandcamp} style={{ textDecoration: 'none' }}>
          <IconButton className={c.iconButton} aria-label="HeaderMenu">
            <Icon className="fab fa-bandcamp" style={FAIconStyle} />
          </IconButton>
        </Link>
        {isTowerRecordEnable && (
          <Link target="_blank" href={discography.links.towerRecords} style={{ textDecoration: 'none' }}>
            <Button variant="outlined" className={c.button}>
              タワーレコードで購入する
            </Button>
          </Link>
        )}
      </div>
      <div>
        <Typography variant="h6" component="h6" className={c.songHeadline}>
          Songs
        </Typography>
        <List dense>
          {songs.map((song, index) => (
            <ListItem key={song} className={c.songTitle}>
              <ListItemText disableTypography primary={renderSongName(index, song)} />
            </ListItem>
          ))}
        </List>
        <div className={c.attributes}>
          {attributes.map((attribute) => (
            <Typography variant="body2" className={c.attribute}>
              {attribute}
            </Typography>
          ))}
        </div>
      </div>
    </div>
  );
};
