export const NEW_ALBUM = {
  title: 'STUDENTS',
  cover: 'https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-students.jpeg?hl=ja',
  songs: [
    'JUST BE HUMAN',
    'DANCED ONCE',
    'SERVICE',
    'CALM DOWN',
    'MEGS',
    'BOOM BOOM',
    'BUSHWICK',
    'DUTY FREE',
    'ALL YOU EVER DO',
    'PUNCH SOUND EFFECTS',
  ],
  isTowerRecordEnable: true,
  attributes: ['Written and performed by Johnnivan', 'Produced by takeshi iwamoto', 'Engineered by daisuke takizawa'],
  links: {
    appleMusic: 'https://music.apple.com/jp/album/students/1501764154',
    spotify: 'https://open.spotify.com/album/5tp45o7OsQmv10wSe1aOw1?si=XlbNTZYURZqXduRQRnnYNw',
    towerRecords: 'https://tower.jp/item/5025012/Students',
    bandcamp: 'https://johnnivan.bandcamp.com/album/students',
  },
  youtubeLinks: ['https://www.youtube.com/embed/wBlNvSkzKJ0', 'https://www.youtube.com/embed/eykU2qi76l4'],
};

export const DISCOGRAPHY = [
  {
    title: 'Students',
    titleFontSize: {
      mobile: 18,
      desktop: 32,
    },
    cover: 'https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-students.jpeg?hl=ja',
    releasedAt: 'Jun 2020',
    songs: [
      'Just Be Human',
      'Danced Once',
      'Service',
      'Calm Down',
      'Megs',
      'Boom Boom',
      'Bushwick',
      'Duty Free',
      'All You Ever Do',
      'Punch Sound Effects',
    ],
    attributes: ['Written and performed by Johnnivan', 'Produced by takeshi iwamoto', 'Engineered by daisuke takizawa'],
    isTowerRecordEnable: true,
    index: 6,
    links: {
      appleMusic: 'https://music.apple.com/jp/album/students/1501764154',
      spotify: 'https://open.spotify.com/album/5tp45o7OsQmv10wSe1aOw1?si=XlbNTZYURZqXduRQRnnYNw',
      towerRecords: 'https://tower.jp/item/5025012/Students',
      bandcamp: 'https://johnnivan.bandcamp.com/album/students',
    },
    youtubeLinks: ['https://www.youtube.com/embed/wBlNvSkzKJ0', 'https://www.youtube.com/embed/eykU2qi76l4'],
  },
  {
    title: 'Danced Once',
    titleFontSize: {
      mobile: 18,
      desktop: 32,
    },
    cover: 'https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-dancedOnce.jpeg?hl=ja',
    releasedAt: 'Apr 2020',
    songs: ['Danced Once'],
    attributes: ['Written and performed by Johnnivan', 'Produced by takeshi iwamoto', 'Engineered by daisuke takizawa'],
    isTowerRecordEnable: false,
    index: 5,
    links: {
      appleMusic: 'https://music.apple.com/jp/album/students/1501764154',
      spotify: 'https://open.spotify.com/album/3vEbJqP8KB8PLeKWmfsLvC',
      towerRecords: '',
      bandcamp: 'https://johnnivan.bandcamp.com/album/students',
    },
    youtubeLinks: ['https://www.youtube.com/embed/eykU2qi76l4'],
  },
  {
    title: 'Pilot',
    titleFontSize: {
      mobile: 18,
      desktop: 32,
    },
    cover: 'https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-pilot.jpeg?hl=ja',
    releasedAt: 'Apr 2019',
    songs: ['Downer', "Nobody's Awake in This House", 'Headband #2', 'Late', 'Green Screen', 'My Man'],
    attributes: ['Written and performed by Johnnivan', 'Produced by takeshi iwamoto', 'Engineered by daisuke takizawa'],
    isTowerRecordEnable: true,
    index: 4,
    links: {
      appleMusic: 'https://music.apple.com/jp/album/pilot-ep/1459999384',
      spotify: 'https://open.spotify.com/album/32iLZQqsFCno54x1Ugbnil',
      towerRecords: 'https://tower.jp/item/4903840/Pilot',
      bandcamp: 'https://johnnivan.bandcamp.com/album/pilot',
    },
    youtubeLinks: ['https://www.youtube.com/embed/P98HA9tisXA'],
  },
  {
    title: 'Community',
    titleFontSize: {
      mobile: 18,
      desktop: 32,
    },
    cover: 'https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-community.jpeg?hl=ja',
    releasedAt: 'Jan 2019',
    songs: ['Community'],
    attributes: ['All music written, performed, produced and engineered by Johnnivan'],
    isTowerRecordEnable: false,
    index: 3,
    links: {
      appleMusic: 'https://music.apple.com/jp/album/community-single/1448443028',
      spotify: 'https://open.spotify.com/album/48Tfp0v4c0IQwm89AaKKjY',
      towerRecords: '',
      bandcamp: 'https://johnnivan.bandcamp.com/track/community-2',
    },
  },
  {
    title: "Nobody's Awake In This House",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover: 'https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-nobody.jpeg?hl=ja',
    releasedAt: 'Sep 2018',
    songs: ["Nobody's Awake in This House", 'Y.G.M.B'],
    attributes: ['All music written, performed, produced and engineered by Johnnivan'],
    isTowerRecordEnable: true,
    index: 2,
    links: {
      appleMusic: 'https://music.apple.com/jp/album/nobodys-awake-in-this-house-single/1435730793',
      spotify: 'https://open.spotify.com/album/7iQXYyz3Ua5RA4SJfcekV2',
      towerRecords: `https://tower.jp/item/4841223/Nobody's-Awake-In-This-House`,
      bandcamp: 'https://johnnivan.bandcamp.com/album/nobodys-awake-in-this-house',
    },
    youtubeLinks: ['https://www.youtube.com/embed/P98HA9tisXA'],
  },
  {
    title: 'Tomorrow Night / I Think I Know You',
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover: 'https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-tomorrow.jpeg?hl=ja',
    releasedAt: 'Apr 2018',
    songs: ['Tomorrow Night', 'I Think I Know You'],
    attributes: ['All music written, performed, produced and engineered by Johnnivan'],
    isTowerRecordEnable: true,
    index: 1,
    links: {
      appleMusic: 'https://music.apple.com/jp/album/tomorrow-night-i-think-i-know-you-single/1387469934',
      spotify: 'https://open.spotify.com/album/6CFGoBFBtHMlJqbVwci1SN',
      towerRecords: 'https://tower.jp/item/4841214/Johnnivan',
      bandcamp: 'https://johnnivan.bandcamp.com/album/tomorrow-night-i-think-i-know-you',
    },
  },
];
